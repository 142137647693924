import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getCompanyClicks, getCompanyClickBreakdown } from '../../utils/api/admin';

function CompanyClicks() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getCompanyClicks();
      setData(res.data);
      setLoaded(true);
    }

    async function loadBreakdown(){
      let res = await getCompanyClickBreakdown();
      setPageBreakdown(res.data);
    }

    loadData();
    loadBreakdown();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function loadData(){
    let res = await getCompanyClicks();
    setData(res.data);
    setLoaded(true);
  }

  async function handleMonthChange(val){
    setLoaded(false);
    setSelectedDate(val);
    loadData(val);
  }

  return (
    <>
      <Helmet>
        <title>Admin - Clicks</title>
      </Helmet>

      <h1>Company Clicks {`(${data.length})`}</h1>

      <div>
      <select className='input select' onChange={(e) => handleMonthChange(e.target.value)}>
          <option>Select date...</option>
          <option value='9/1/2024'>September 2024</option>
          <option value='8/1/2024'>August 2024</option>
          <option value='7/1/2024'>July 2024</option>
          <option value='6/1/2024'>June 2024</option>
          <option value='5/1/2024'>May 2024</option>
          <option value='4/1/2024'>April 2024</option>
          <option value='3/1/2024'>March 2024</option>
          <option value='2/1/2024'>February 2024</option>
          <option value='1/1/2024'>January 2024</option>
          <option value='12/1/2023'>December 2023</option>
          <option value='11/1/2023'>November 2023</option>
          <option value='10/1/2023'>October 2023</option>
          <option value='9/1/2023'>September 2023</option>
          <option value='8/1/2023'>August 2023</option>
        </select>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {pageBreakdown.length > 0 && (
        <table className='admindashboard__table table'>
          <tr>
            <th>Source</th>
            <th>Count</th>
          </tr>

          {pageBreakdown.map(item => (
            <tr>
              <td>{item.source}</td>
              <td>{item.count}</td>
            </tr>
          ))}
        </table>
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Date</th>
              <th>Source</th>
              <th>Company</th>
              <th></th>
              <th>Buyer</th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td><strong>#{index}.</strong></td>
                <td>
                  {new Date(item.date_created).toDateString()}
                </td>

                <td>
                  {item.source}
                </td>
                
                <td>
                  <div className='buyerdashboard__recommendation-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
                </td>

                <td>
                  {item.name}
                </td>

                <td>
                  {item.first_name} {item.last_name}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default CompanyClicks;