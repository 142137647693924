import './CompanyFeature.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile, getFeaturesMap, updateFeatureMap } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
 import { isSeller } from '../../services/auth.service';
 import { refresh } from '../../services/auth.service';
import * as FaIcons from 'react-icons/fa';

const IconDisplay = ({ iconName }) => {
  // Dynamically get the icon component from the FaIcons object
  const IconComponent = FaIcons[iconName];

  // If the icon is found, render it
  if (IconComponent) {
    return <IconComponent />;
  }

  // Fallback if icon is not found
  return <p>Icon not found</p>;
};


const CompanyFeature = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [featuresmap, setFeaturesmap] = useState([]);
  const navigate = useNavigate();

  async function loadFeaturesMap(){
    let res = await getFeaturesMap();

    if (res.success){
      setFeaturesmap(res.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    async function loadFeaturesMap(){
      let res = await getFeaturesMap();

      if (res.success){
        setFeaturesmap(res.data);
        setLoading(false);
      }
    }
      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setLoading(false);
        setSuccess(true);
      }

      else if (response.status === 403){
        await refresh();
        let response = await getUserProfile();

        if (response.success){
          setUser(response.data);
          setLoading(false);
          setSuccess(true);
        }
      }

      else{
        setLoading(false);
      }
    }

    loadUserProfile();
    loadFeaturesMap();
  }, []);

  async function handleFeatureClick(feature_id, selected){
    let res = await updateFeatureMap(feature_id, !selected);

    if (res.success){
      loadFeaturesMap();
    }
  }

  return (
    <div className="featuresmap">
      {loading && (
        <Spinner />
      )}

      {loading === false && success == true && (
        <>
          <div className='companyfeature__list'>
            {featuresmap.map(item => (
              <div className={`companyfeature__list-item ${item.selected == true ? 'selected': ''}`} onClick={() => handleFeatureClick(item.feature_id, item.selected)}>
                <span><IconDisplay iconName={item.icon} />
                </span>
                <span className='ml-10'>{item.name}</span>
              </div> 
            ))}
          </div>
        </>
      )}
    </div>
  )
}

  export default CompanyFeature;